<template>
  <div v-show="globalModalStore.globalModalVisible" ref="globalModalRef" class="">
    <div class="fixed z-1000000 top-0 left-0 w-full h-full bg-black opacity-50 cursor-pointer" @click="closeModal(false)" />
    <div
      class="global-modal fixed z-1000000 left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 w-full sm:w-120 px-4 py-6 bg-white shadow overflow-y-auto overflow-hidden">
      <button v-if="globalModalStore.modalClosable" class="absolute right-0 top-0 mt-2 mr-2 modal-close-button" @click="closeModal(false)">
        <svg id="closebutton-size" width="24" height="24" color="black">
          <use xlink:href="#clear" />
        </svg>
      </button>
      <moments-report-modal
        v-if="globalModalStore.activeModalContent === 'ReportForm' || globalModalStore.activeModalContent === 'ReportConfirmation'" />
      <producer-profile-download-app-modal-component v-else-if="globalModalStore.activeModalContent === 'DownloadApp'" />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from '@vue/composition-api';
import { globalModalStore } from '@/store/globalModal.store';
import { activeModalContent } from '@/store/globalModal.store';
import TrackingUtils from '@/utils/tracking/tracking.utils';

export default defineComponent({
  name: 'global-modal',
  components: {
    momentsReportModal: () => import('../components/moments/modals/momentsReportModal.component.vue'),
    producerProfileDownloadAppModalComponent: () => import('../components/moments/modals/producerProfileDownloadAppModal.component.vue'),
  },
  props: {},
  data() {
    return {
      showReportForm: false,
      showReportConfirmation: false,
      showProducerProfileLoginModal: false,
      showProducerProfileDownloadAppModal: false,
      globalModalStore,
    };
  },
  beforeDestroy() {},
  mounted() {},
  methods: {
    showModal(childComponent: 'ReportForm' | 'ReportConfirmation' | 'LoginForm' | 'DownloadApp') {
      switch (childComponent) {
        case 'ReportForm':
          this.showReportForm = true;
          break;
        case 'ReportConfirmation':
          this.showReportConfirmation = true;
          break;
        case 'LoginForm':
          this.showProducerProfileLoginModal = true;
          break;
        case 'DownloadApp':
          this.showProducerProfileDownloadAppModal = true;
          break;
        default:
          break;
      }
    },
    closeModal(result: any) {
      if (this.globalModalStore.modalClosable || result) {
        this.trackCloseEvent(this.globalModalStore.activeModalContent);
        globalModalStore.setGlobalModalInvisible();
      }
    },
    trackCloseEvent(activeModalContent: activeModalContent) {
      const modalContentToElementNameMap = new Map<activeModalContent, string>([
        ['ReportForm', 'report_gallery_cancel_click'],
        ['ReportConfirmation', 'report_gallery_close_click'],
        ['DownloadApp', 'abort-download-app_click'],
      ]);
      TrackingUtils.trackSnowplowEvent({
        eventName: 'pageElement.click',
        eventCategory: 'pageElement',
        eventAction: 'pageElement.clickInternal',
        eventLabel: this.globalModalStore.reportDetails?.galleryTitle || this.globalModalStore.reportDetails?.producerDisplayName || 'n/a',
        pageElementId: this.globalModalStore.reportDetails?.galleryId || this.globalModalStore.reportDetails?.producerId,
        pageElementName: modalContentToElementNameMap.get(activeModalContent) || 'n/a',
        pageElementType: this.globalModalStore.reportDetails?.feedType || 'n/a',
        pageElementPosition: `${this.globalModalStore.reportDetails?.producerId} | ${this.globalModalStore.reportDetails?.producerDisplayName}`,
        pageElementCreative: this.globalModalStore.reportDetails?.elementCreative || 'x',
      });
    },
  },
});
</script>

<style scoped>
.global-modal {
  max-width: calc(100vw - 2rem);
  max-height: 95vh;
}
#closebutton-size {
  width: 1.5rem;
  height: 1.5rem;
}
</style>
