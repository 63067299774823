<template>
  <div id="sticky-button" class="bg-white fixed py-4 px-16 w-full z-900000 sm:hidden flex justify-center items-center bottom-0 left-0">
    <a
      :href="link"
      target="_blank"
      data-modal="bi-follow-widget"
      class="btn btn-primary inline-block text-center leading-loose"
      @click="onLinkClick">
      {{ buttonLabel }}
    </a>
  </div>
</template>

<script lang="ts">
import { defineComponent } from '@vue/composition-api';
import TrackingUtils from '@/utils/tracking/tracking.utils';

export default defineComponent({
  name: 'sticky-button',
  props: {
    buttonLabel: {
      type: String,
      default: 'Jetzt zum Angebot',
    },
    link: {
      type: String,
      default: '',
    },
  },
  mounted() {
    const stickyFooter = document.querySelector('#sticky-footer');
    if (stickyFooter) {
      stickyFooter.classList.add('none', 'md:block');
    }
  },
  methods: {
    onLinkClick(event: any) {
      const element = event.currentTarget;

      TrackingUtils.trackEvent({
        event: 'b_commerce_clicks',
        eventCategory: 'commerce_clicks',
        eventAction: element.hostname,
        eventLabel: element.href,
        eventValue: undefined,
      });
    },
  },
});
</script>

<style scoped>
/* Bigger padding for iPhone X and other similar screens */
@supports (padding: env(safe-area-inset-bottom)) {
  #sticky-button {
    padding-bottom: calc(env(safe-area-inset-bottom) + 1rem);
    height: calc(env(safe-area-inset-bottom) + 4rem);
  }
}
</style>
