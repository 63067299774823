<template>
  <gallery-slider
    v-if="inView"
    class="gallery-slider-space-reservation"
    :ad-orientation="adOrientation"
    :gallery="gallery"
    :in-article="articleGallery === 'true'"
    :gallery-name="galleryName"
    :gallery-id="galleryId"
    :related-gallery-uuid="relatedGalleryUuid">
    <template>
      <slot />
    </template>
  </gallery-slider>
</template>

<script lang="ts">
import { defineComponent } from '@vue/composition-api';
import inViewComposite from '@/composables/in-view.composite';
import TrackingUtils from '@/utils/tracking/tracking.utils';
import * as ivw from '@/utils/ivw.utils';

export default defineComponent({
  name: 'teaser-gallery-slider',
  components: {
    gallerySlider: () => import(/* webpackChunkName: "gallery-slider" */ '../components/gallerySlider.component.vue'),
  },
  props: {
    galleryMap: {
      type: String,
      required: true,
    },
    galleryName: {
      type: String,
    },
    galleryId: {
      type: String,
    },
    articleGallery: {
      type: String,
      required: false,
    },
    adsEnabled: {
      type: String,
      required: false,
    },
    adOrientation: {
      type: String,
      default: 'ghor',
    },
    showSlot: {
      type: String,
      default: 'false',
    },
    relatedGalleryUuid: {
      type: String,
      required: true,
    },
  },
  setup(props, ctx) {
    const { inView } = inViewComposite(ctx);
    return {
      inView,
    };
  },
  data() {
    return {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      gallery: [] as any[],
      lastIVWPageviewTrackingTop: null as null | number,
    };
  },
  mounted() {
    this.parseGallery();
    if (this.articleGallery === 'true') {
      this.initGalleryTrackingObserver(this.galleryName || '');
    }
  },
  methods: {
    getTopPosition(elem: Element) {
      // crossbrowser version
      const box = elem.getBoundingClientRect();

      const body = document.body;
      const docEl = document.documentElement;

      const scrollTop = window.pageYOffset || docEl.scrollTop || body.scrollTop;
      const clientTop = docEl.clientTop || body.clientTop || 0;
      const top = box.top + scrollTop - clientTop;
      return Math.round(top);
    },
    parseGallery() {
      this.gallery = JSON.parse(this.galleryMap);
      if (!this.gallery) {
        this.gallery = [];
      }
      if (this.adsEnabled === 'true') {
        let adIndex = 1;
        this.gallery = this.gallery.reduce((newArray, currentValue, index) => {
          if (index > 0 && index % 4 === 0) {
            return [
              ...newArray,
              {
                agencyCustom: '',
                title: '',
                ad: true,
                url: '/images/gallery/ad_background_new.png',
                divName: `bunte_web_${this.adOrientation}_${adIndex}`,
                adIndex: adIndex++,
              },
              currentValue,
            ];
          }
          return [...newArray, currentValue];
        }, []);
      }
      if (this.showSlot === 'true' && this.$scopedSlots.default) {
        this.gallery.push({
          agencyCustom: '',
          title: '',
          ad: false,
          gallery: true,
        });
      }
    },
    initGalleryTrackingObserver(galleryName: string) {
      const observer = new IntersectionObserver(
        (entries: IntersectionObserverEntry[]) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              const elem = entry.target;
              const index = elem.getAttribute('data-image-index') as string;
              if (entry.intersectionRatio >= 0.75) {
                TrackingUtils.trackEvent({
                  event: 'b_gallery_view',
                  eventCategory: 'gallery.view',
                  eventAction: galleryName,
                  eventLabel: `pic_${index}`,
                });
                if (this.lastIVWPageviewTrackingTop === null || this.lastIVWPageviewTrackingTop < this.getTopPosition(entry.target) - 650) {
                  // we only fire the ive pageview at most every 650 vertical pixels
                  // as - according to the IVW rules - we need to have at max a vertical spacing of
                  // 650px (see TT-1204)
                  this.lastIVWPageviewTrackingTop = this.getTopPosition(entry.target);
                  ivw.pageView();
                }
              }
            }
          });
        },
        {
          rootMargin: '0px',
          threshold: 0.8,
        }
      );

      const mobileImages = document.querySelectorAll('.mobile-gallery-slide');
      mobileImages.forEach((imageEl) => observer.observe(imageEl));
    },
  },
});
</script>

<style scoped>
.gallery-slider-space-reservation {
  min-height: 820px;
}
</style>
