<template>
  <button
    class="topbar-teaser relative rounded-full border-2 flex p-2px trans ml-2 mr-1 sm:mx-2"
    :class="['border-red', visited && !isActive ? 'opacity-50' : '', isActive ? 'topbar-active bg-white' : '']"
    @click="itemClick()">
    <span
      v-if="!visited && !isActive"
      class="block absolute top-0 right-0 w-4 h-4 border-2 border-white rounded-full -mt-2px -mr-2px grad-red bg-red" />
    <img v-if="article.thumbImage" class="h-auto block rounded-full" :src="article.thumbImage" :title="article.id" :alt="article.kicker" />
  </button>
</template>

<script lang="ts">
import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'topbar-item',
  props: {
    article: {
      type: Object,
    },
    itemKey: {
      type: Number,
    },
    isActive: {
      type: Boolean,
    },
    visited: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    itemClick() {
      this.$emit('itemClick', this.itemKey);
    },
  },
});
</script>
<style scoped>
.topbar-teaser {
  max-width: 4rem;
  max-height: 4rem;
}

.topbar-active {
  transform: scale(1.33) translate(0, 0.45rem);
}

@screen sm {
  .grad-red,
  .grad-inside {
    width: 1.25rem;
    height: 1.25rem;
  }
}
</style>
