<template>
  <svg class="h-6 w-6 xs:h-7 xs:w-7 z-1 toolbarIcon" @click="onShareClick">
    <use xlink:href="#moments-share-icon" />
  </svg>
</template>

<script lang="ts">
import { defineComponent } from '@vue/composition-api';
import TrackingUtils from '../../utils/tracking/tracking.utils';

import { slugify } from '../../utils/slugify.utils';

export default defineComponent({
  name: 'moments-share-gallery-icon',
  components: {},
  props: {
    galleryid: {
      type: String,
      required: true,
    },
    gallerytitle: {
      type: String,
      required: true,
    },
  },
  methods: {
    async onShareClick() {
      const currentHostname = window.location.hostname;
      const url = `https://${currentHostname}/moments/galerie/${slugify(this.gallerytitle)}-${this.galleryid}`;
      const title = this.gallerytitle;
      TrackingUtils.trackSnowplowEvent({
        eventName: 'pageElement.click',
        eventCategory: 'pageElement',
        eventAction: 'pageElement.clickInternal',
        eventLabel: this.gallerytitle,
        pageElementId: this.galleryid,
        pageElementName: 'share_gallery_click',
        pageElementType: 'momentsfeed',
        pageElementCreative: 'paper plane',
      });
      if (window.flutter_inappwebview) {
        window.flutter_inappwebview.callHandler('shareUrl', url, title);
      } else {
        try {
          await navigator.share({
            url: url,
            title: title,
          });
        } catch (err) {
          // TODO: do we want to track abort?
        }
      }
    },
  },
});
</script>

<style scoped>
svg {
  cursor: pointer;
}
/*TODO: write all the css in mobile first in order to avoid the crashing of the breakpoint at 520px or leave it at 519px*/
@media screen and (max-width: 519px) {
  svg {
    pointer-events: bounding-box;
    transform: translateZ(0);
    filter: drop-shadow(0px 0px 7px rgba(0, 0, 0, 1));
    -webkit-transform: translateZ(0);
    -webkit-filter: drop-shadow(0px 0px 7px rgba(0, 0, 0, 1));
  }
  .icon-hide {
    opacity: 0;
  }
}
</style>
