<template>
  <div v-if="isLoggedIn" class="container relative">
    <div
      class="user-overlay hidden fixed w-full h-full left-0 top-0 cursor-pointer bg-black opacity-50 z-900000"
      @click="globalStore.toggleUserNav(false)" />
    <nav class="user-nav hidden w-60 bg-white z-900000 p-4 absolute mr-7 xs:mr-3 sm:mr-10 md:-mr-4 top-0 right-0 shadow-md">
      <a
        :href="loginUrl"
        target="_blank"
        rel="noopener noreferrer"
        class="nav-item--einstellungen inline-flex items-center text-black w-full py-2 text-left">
        <div class="flex justify-between w-60">
          <div class="flex">
            <svg width="24" height="24" class="mr-1">
              <use xlink:href="#user" />
            </svg>
            Mein Konto
          </div>
          <svg width="24" height="24" class="mr-1">
            <use xlink:href="#external-link" />
          </svg>
        </div>
      </a>
      <a href="/settings" class="nav-item--einstellungen inline-flex items-center text-black w-full py-2 text-left">
        <svg width="24" height="24" class="mr-1">
          <use xlink:href="#settings" />
        </svg>
        Newsletter verwalten
      </a>
      <button class="inline-flex items-center text-black w-full py-2 text-left" @click.stop.prevent="logout">
        <svg width="24" height="24" class="mr-1">
          <use xlink:href="#logout" />
        </svg>
        Abmelden
      </button>
    </nav>
  </div>
</template>

<script lang="ts">
import { defineComponent } from '@vue/composition-api';
import { auth0UserStore } from '@/store/user.store';
import { globalStore } from '@/store/global.store';

export default defineComponent({
  name: 'user-nav',
  data() {
    return {
      auth0UserStore,
      globalStore,
      auth0Loaded: false,
    };
  },
  computed: {
    loginUrl(): string {
      return process.env.VUE_APP_AUTH0_USERSETTINGS;
    },
  },
  async mounted() {
    await auth0UserStore.getClient(true);
    this.auth0Loaded = true;
    auth0UserStore.handleRedirect();
    if (await auth0UserStore.isAuthenticated()) {
      auth0UserStore.trackLoggedinStatus();
    }
  },
  methods: {
    async isLoggedIn(): Promise<boolean> {
      const l = await this.auth0UserStore.isAuthenticated();
      console.log('Auth0: isLoggedIn', l);
      return l;
    },
    async logout() {
      auth0UserStore.redirectUrl('auth0LogoutRedirect', true);
      await auth0UserStore.logout();
    },
  },
});
</script>

<style scoped>
.user-nav {
  top: -1.5rem;
  right: 0.125rem;
}

.page-type--article .user-nav {
  top: 1rem;
}

.user-overlay {
  top: 3.25rem;
}

.user-nav:before {
  content: '';
  position: absolute;
  right: 1.75rem;
  top: -8px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 10px 8px 10px;
  border-color: transparent transparent #fff transparent;
}

@media (min-width: 768px) {
  .user-nav {
    top: -1.5rem;
    right: 0.25rem;
  }
  .user-overlay {
    top: 4.25rem;
  }
}

@media (min-width: 768px) and (max-width: 996px) {
  .user-nav:before {
    right: 2rem;
    margin-right: -2px;
  }
}

@media (min-width: 996px) {
  .user-nav {
    top: -3.5rem;
    right: 1.5rem;
  }
  .user-overlay {
    top: 5.5rem;
  }
  .page-type--article .user-nav {
    top: -3.5rem;
  }
}
</style>
<style>
.user-nav-active .user-nav,
.user-nav-active .user-overlay {
  display: block;
}

@media (min-width: 996px) {
  .page-type--article.scrolled .user-nav,
  .scrolled .user-nav {
    top: 0.75rem;
    right: 0.75rem;
  }
  .scrolled .user-overlay {
    top: 4rem;
  }
}
</style>
