<template>
  <div v-if="showBlocker" class="fe-blocker relative z-1 md:-mx-16 -mt-4">
    <svg id="svg-image-blur" width="1" height="1">
      <filter id="blur-effect-1">
        <feGaussianBlur stdDeviation="5" />
      </filter>
    </svg>
    <div class="premium-content-overlay" />
  </div>
</template>

<script lang="ts">
import { defineComponent } from '@vue/composition-api';
import { userStore } from '@/store/user.store';
import TrackingUtils from '@/utils/tracking/tracking.utils';

export default defineComponent({
  name: 'content-blocker',
  components: {},

  data() {
    return {
      userStore: userStore,
      forceHide: false,
      isConfirmationStage: false,
    };
  },
  computed: {
    showBlocker() {
      if (this.forceHide) return false; // forceHide is used to forcefully hide content blocker (closing pending dialog)
      if (!this.userStore.loggedIn) return true; // Show when user is not paid
      return false;
    },
  },
  watch: {
    showBlocker() {
      this.setBlurredContent();
    },
  },
  created() {
    this.setBlurredContent();
  },
  methods: {
    handlePaywallClose(user: any) {
      this.isConfirmationStage = false;
      if (!this.userStore.user) {
        TrackingUtils.setPending();
        TrackingUtils.setUserEmail(user.email);
      }
      window.location.reload();
    },
    onStageChange(stage: any) {
      this.isConfirmationStage = stage === 'confirmation';
    },
    setBlurredContent() {
      if (this.showBlocker) {
        document.getElementById('article-content')?.classList.add('locked-content');
        document.getElementById('article-content-body')?.classList.add('blurred-content', 'relative');
      } else {
        document.getElementById('article-content')?.classList.remove('locked-content');
        document.getElementById('article-content-body')?.classList.remove('blurred-content', 'relative');
      }
    },
  },
});
</script>

<style lang="postcss">
.fe-blocker:before {
  @apply absolute top-0 left-0 w-full;
  height: 100px;
  margin-top: -100px;
  content: '';
  background: -ms-linear-gradient(top, rgba(255, 255, 255, 0) 0%, #fff 100%);
  background: linear-gradient(rgba(255, 255, 255, 0), #fff);
}

.fe-blocker .fe-inside-showcase {
  @apply block relative z-1;
}

.blurred-content {
  filter: progid:DXImageTransform.Microsoft.Blur(Strength=4);
  filter: url(#blur-effect-1);
  filter: blur(4px);
  opacity: 0.53;
  user-select: none;
}

.premium-content-overlay:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: transparent;
  z-index: -1;
}

/* fix pro IE - no blur effect, only opacity */
@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
  .blurred-content {
    opacity: 0.15;
  }
}

@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
  .blurred-content p,
  .blurred-content a,
  .blurred-content span {
    color: #aaa;
    font-size: 11px;
  }

  .blurred-content p {
    line-height: 2;
  }
}

/* disable all interactions inside blurred content */
.blurred-content {
  pointer-events: none;
}

@screen sm {
  .locked-content:before {
    left: 45px;
    right: 45px;
  }
}

@screen sm {
  .locked-content-form {
    margin-left: -45px;
    margin-right: -45px;
  }
}

@screen md {
  .locked-content-form {
    width: 880px;
    margin-left: 0;
  }
}
</style>
