<template>
  <div class="button-div" :class="!isLoaded ? 'hidden' : ''">
    <button
      class="producer-button bg-momentsOrange flex justify-center items-center h-10 rounded text-center p-2"
      :class="isFollowing ? 'hidden' : ''"
      @click="followButtonClick()">
      <span class="text-base font-normal text-center text-white">FOLGEN</span>
    </button>
    <button
      class="producer-button border-momentsOrange border-solid border-2 text-momentsOrange flex justify-center items-center h-10 rounded text-center p-2 bg-white"
      :class="isFollowing ? '' : 'hidden'"
      @click="unfollowButtonClick()">
      <svg height="22px" width="22px" class="check-icon">
        <use xlink:href="#check-icon" />
      </svg>
      <span class="text-momentsOrange text-base font-normal text-center">GEFOLGT</span>
    </button>
  </div>
</template>

<script lang="ts">
import { globalModalStore } from '@/store/globalModal.store';
import { momentsStore } from '@/store/moments.store';
import { userStore } from '@/store/user.store';
import { defineComponent } from '@vue/composition-api';
import TrackingUtils from '../../utils/tracking/tracking.utils';

export default defineComponent({
  name: 'moments-producer-profile-follow-button',
  components: {},
  props: {
    producerUuid: {
      type: String,
      required: true,
    },
    producerDisplayName: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      globalModalStore,
      isFollowing: false,
      isLoaded: false,
    };
  },
  computed: {},
  watch: {},
  async mounted() {
    this.isFollowing = await momentsStore.isFollowingMomentsUser(this.producerUuid);
    this.isLoaded = true;
  },
  methods: {
    followButtonClick() {
      TrackingUtils.trackSnowplowEvent({
        eventName: 'pageElement.click',
        eventCategory: 'pageElement',
        eventAction: 'pageElement.clickInternal',
        eventLabel: this.producerDisplayName,
        pageElementId: this.producerUuid,
        pageElementName: 'follow_click',
        pageElementType: 'producerprofile_consumer-view',
        pageElementPosition: 'n/a',
        pageElementCreative: 'n/a',
      });
      this.globalModalStore.setReportDetails({
        producerDisplayName: this.producerDisplayName,
        producerId: this.producerUuid,
        galleryId: '',
        galleryTitle: '',
        feedType: 'producerprofile_consumer-view',
        elementCreative: 'system-dialogue',
      });
      if (window.innerWidth > 996) {
        if (userStore.loggedIn) {
          momentsStore.followMomentsUser(this.producerUuid);
          this.isFollowing = true;
        } else {
          this.globalModalStore.setGlobalActiveModalContent('LoginForm');
          this.globalModalStore.setGlobalModalVisible();
        }
      } else {
        this.globalModalStore.setGlobalActiveModalContent('DownloadApp');
        this.globalModalStore.setGlobalModalVisible();
      }
    },
    unfollowButtonClick() {
      TrackingUtils.trackSnowplowEvent({
        eventName: 'pageElement.click',
        eventCategory: 'pageElement',
        eventAction: 'pageElement.clickInternal',
        eventLabel: this.producerDisplayName,
        pageElementId: this.producerUuid,
        pageElementName: 'unfollow_click',
        pageElementType: 'producerprofile_consumer-view',
        pageElementPosition: 'n/a',
        pageElementCreative: 'n/a',
      });
      this.globalModalStore.setReportDetails({
        producerDisplayName: this.producerDisplayName,
        producerId: this.producerUuid,
        galleryId: '',
        galleryTitle: '',
        feedType: 'producerprofile_consumer-view',
        elementCreative: 'system-dialogue',
      });
      if (window.innerWidth > 996) {
        if (userStore.loggedIn) {
          momentsStore.unfollowMomentsUser(this.producerUuid);
          this.isFollowing = false;
        } else {
          this.globalModalStore.setGlobalActiveModalContent('LoginForm');
          this.globalModalStore.setGlobalModalVisible();
        }
      } else {
        this.globalModalStore.setGlobalActiveModalContent('DownloadApp');
        this.globalModalStore.setGlobalModalVisible();
      }
    },
  },
});
</script>

<style>
.producer-button {
  width: 108px;
  animation: fadeInAnimation ease 0.3s;
  box-shadow: 0 0 20px 0 rgba(187, 187, 187, 0.65);
}
</style>
