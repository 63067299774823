<template>
  <div class="mx-auto my-10 w-20 text-center">
    <div class="sk-child sk-bounce1 bg-grey-300 inline-block" />
    <div class="sk-child sk-bounce2 bg-grey-300 inline-block" />
    <div class="sk-child sk-bounce3 bg-grey-300 inline-block" />
  </div>
</template>
<script lang="ts">
import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'loader',
});
</script>
<style scoped>
.sk-child {
  width: 20px;
  height: 20px;
  border-radius: 100%;
  animation: sk-three-bounce 1.4s ease-in-out 0s infinite both;
}

.sk-bounce1 {
  animation-delay: -0.32s;
}

.sk-bounce2 {
  animation-delay: -0.16s;
}

@keyframes sk-three-bounce {
  0%,
  80%,
  100% {
    transform: scale(0);
  }
  40% {
    transform: scale(1);
  }
}
</style>
