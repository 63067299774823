<template>
  <div>
    <div v-if="!expanded">
      <div class="sm:hidden bg-white fixed z-1000000 inset-x-0 bottom-0 flex justify-end py-1 pr-4 items-center mew-border">
        <svg class="h-4 w-4 mr-2">
          <use xlink:href="#sliders-icon" />
        </svg>
        <div
          class="border rounded-sm border-black py-1 px-2 uppercase text-xxs leading-normal flex cursor-pointer items-center"
          @click="onSortClick">
          <span>sortieren nach</span>
          <svg class="h-4 w-4 transform rotate-90">
            <use xlink:href="#chevron_right" />
          </svg>
        </div>
      </div>
      <div class="hidden sm:block bg-white fixed z-1000000 bottom-0 right-0 mb-4 mr-4 desktop-rounded-top-corners flex py-2 uppercase px-4">
        <div class="flex cursor-pointer items-center" @click="onSortClick">
          <svg class="h-6 w-6 mr-4">
            <use xlink:href="#sliders-icon" />
          </svg>
          <span class="pr-2 mr-8">sortieren nach</span>
          <svg class="h-6 w-6 transform rotate-90">
            <use xlink:href="#chevron_right" />
          </svg>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="sm:hidden">
        <div class="fixed z-1000000 top-0 left-0 w-full h-full bg-black opacity-50 cursor-pointer" @click="onSortClick" />
        <div class="z-1000000 bg-white fixed inset-x-0 bottom-0 flex flex-col justify-end items-center mew-rounded-top-corners">
          <div class="border-2 border-grey-300 w-8 mt-2 mb-4" />
          <div class="font-bold text-l pb-2">Sortieren nach</div>
          <div class="border-b border-grey-300 w-full" />
          <ul class="flex flex-col w-full px-4 py-5">
            <li class="flex justify-between">
              <div class="flex items-center">
                <svg class="h-4 w-4 mr-2">
                  <use xlink:href="#moments-calender-1" />
                </svg>
                <a
                  href="/moments/"
                  :class="{ 'font-bold': currentsortorder === 'creationDate' }"
                  class="text-sm"
                  @click="changeSortOrder('creationDate')"
                  >Veröffentlichungsdatum</a
                >
              </div>
              <svg v-if="currentsortorder === 'creationDate'" class="h-4 w-4">
                <use xlink:href="#check-icon" />
              </svg>
            </li>
            <li class="flex justify-between pt-4">
              <div class="flex items-center">
                <svg class="h-4 w-4 mr-2 fill-black">
                  <use xlink:href="#moments-calender-2" />
                </svg>
                <a
                  href="/moments/latestEvent/"
                  :class="{ 'font-bold': currentsortorder === 'eventTime' }"
                  class="text-sm"
                  @click="changeSortOrder('eventTime')"
                  >Veranstaltungsdatum</a
                >
              </div>
              <svg v-if="currentsortorder === 'eventTime'" class="h-4 w-4">
                <use xlink:href="#check-icon" />
              </svg>
            </li>
            <li class="flex justify-between pt-4 items-center">
              <div class="flex items-center mr-4">
                <svg class="h-4 w-4 mr-2 fill-black">
                  <use xlink:href="#moments-most-liked" />
                </svg>
                <a
                  href="/moments/mostLiked/"
                  :class="{ 'font-bold': currentsortorder === 'mostLiked' }"
                  class="text-sm"
                  @click="changeSortOrder('mostLiked')"
                  >Beliebteste</a
                >
              </div>
              <svg v-if="currentsortorder === 'mostLiked'" class="h-6 w-6">
                <use xlink:href="#check-icon" />
              </svg>
            </li>
          </ul>
        </div>
      </div>
      <div class="hidden sm:block bg-white fixed z-1000000 bottom-0 right-0 mb-4 mr-4 desktop-rounded-top-corners flex py-2 px-4 flex-col">
        <div class="flex cursor-pointer justify-between" @click="onSortClick">
          <div class="flex">
            <svg class="h-6 w-6 mr-2">
              <use xlink:href="#sliders-icon" />
            </svg>
            <span class="uppercase">sortieren nach</span>
          </div>
          <svg class="h-6 w-6 transform -rotate-90">
            <use xlink:href="#chevron_right" />
          </svg>
        </div>
        <ul class="flex flex-col pt-5">
          <li class="flex justify-between items-center">
            <div class="flex items-center mr-4">
              <svg class="h-6 w-6 mr-2">
                <use xlink:href="#moments-calender-1" />
              </svg>
              <a
                href="/moments/"
                :class="{ 'font-bold': currentsortorder === 'creationDate' }"
                class="text-sm"
                @click="changeSortOrder('creationDate')"
                >Veröffentlichungsdatum</a
              >
            </div>
            <svg v-if="currentsortorder === 'creationDate'" class="h-6 w-6">
              <use xlink:href="#check-icon" />
            </svg>
          </li>
          <li class="flex justify-between pt-2 items-center">
            <div class="flex items-center mr-4">
              <svg class="h-6 w-6 mr-2 fill-black">
                <use xlink:href="#moments-calender-2" />
              </svg>
              <a
                href="/moments/latestEvent/"
                :class="{ 'font-bold': currentsortorder === 'eventTime' }"
                class="text-sm"
                @click="changeSortOrder('eventTime')"
                >Veranstaltungsdatum</a
              >
            </div>
            <svg v-if="currentsortorder === 'eventTime'" class="h-6 w-6">
              <use xlink:href="#check-icon" />
            </svg>
          </li>
          <li class="flex justify-between pt-2 items-center">
            <div class="flex items-center mr-4">
              <svg class="h-6 w-6 mr-2 fill-black">
                <use xlink:href="#moments-most-liked" />
              </svg>
              <a
                href="/moments/mostLiked/"
                :class="{ 'font-bold': currentsortorder === 'mostLiked' }"
                class="text-sm"
                @click="changeSortOrder('mostLiked')"
                >Beliebteste</a
              >
            </div>
            <svg v-if="currentsortorder === 'mostLiked'" class="h-6 w-6">
              <use xlink:href="#check-icon" />
            </svg>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import TrackingUtils from '../../utils/tracking/tracking.utils';
import { defineComponent } from '@vue/composition-api';
export default defineComponent({
  name: 'moments-sort-bar-widget',
  props: {
    currentsortorder: {
      type: String,
      default: 'creationDate',
      required: false,
      validator(value: string) {
        return ['creationDate', 'eventTime', 'mostLiked'].includes(value);
      },
    },
  },
  data() {
    return {
      expanded: false,
    };
  },
  methods: {
    changeSortOrder(sortOrder: String) {
      if (sortOrder === 'eventTime') {
        TrackingUtils.trackSnowplowEvent({
          eventName: 'pageElement.click',
          eventCategory: 'pageElement',
          eventAction: 'pageElement.clickInternal',
          eventLabel: 'n/a',
          pageElementId: 'n/a',
          pageElementName: 'sort_feed_event-date_click',
          pageElementType: 'momentsfeed',
          pageElementPosition: 'n/a',
          pageElementCreative: 'n/a',
        });
      } else if (sortOrder === 'creationDate') {
        TrackingUtils.trackSnowplowEvent({
          eventName: 'pageElement.click',
          eventCategory: 'pageElement',
          eventAction: 'pageElement.clickInternal',
          eventLabel: 'n/a',
          pageElementId: 'n/a',
          pageElementName: 'sort_feed_create-date_click',
          pageElementType: 'momentsfeed',
          pageElementPosition: 'n/a',
          pageElementCreative: 'n/a',
        });
      } else if (sortOrder === 'mostLiked') {
        TrackingUtils.trackSnowplowEvent({
          eventName: 'pageElement.click',
          eventCategory: 'pageElement',
          eventAction: 'pageElement.clickInternal',
          eventLabel: 'n/a',
          pageElementId: 'n/a',
          pageElementName: 'sort_feed_most-popular_click',
          pageElementType: 'momentsfeed',
          pageElementPosition: 'n/a',
          pageElementCreative: 'n/a',
        });
      }
    },
    onSortClick() {
      if (!this.expanded) {
        TrackingUtils.trackSnowplowEvent({
          eventName: 'pageElement.click',
          eventCategory: 'pageElement',
          eventAction: 'pageElement.clickInternal',
          eventLabel: 'n/a',
          pageElementId: 'n/a',
          pageElementName: 'sort_feed_click',
          pageElementType: 'momentsfeed',
          pageElementPosition: 'n/a',
          pageElementCreative: 'n/a',
        });
      } else {
        TrackingUtils.trackSnowplowEvent({
          eventName: 'pageElement.click',
          eventCategory: 'pageElement',
          eventAction: 'pageElement.clickInternal',
          eventLabel: 'n/a',
          pageElementId: 'n/a',
          pageElementName: 'sort_feed_cancel_click',
          pageElementType: 'momentsfeed',
          pageElementPosition: 'n/a',
          pageElementCreative: 'n/a',
        });
      }
      this.expanded = !this.expanded;
    },
  },
});
</script>

<style lang="postcss" scoped>
.mew-border {
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.65);
  border: solid 1px var(--bunte-border-grey);
}

.mew-rounded-top-corners {
  border-top-left-radius: 15px !important;
  border-top-right-radius: 15px !important;
  box-shadow: 0 0 11px 0 rgba(0, 0, 0, 0.25);
}

.desktop-rounded-top-corners {
  border-radius: 3px;
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.65);
}
</style>
