<template>
  <div class="topbar-slider relative sm:w-3/4 md:w-1/2 mx-auto mt-2 sm:mt-3">
    <hooper
      ref="topbarCarousel"
      :settings="hooperSettings"
      class="bg-white shadow px-10 sm:px-16 py-4 sm:py-5 h-auto w-full outline-none"
      @slide="handleActionSlide"
      @beforeSlide="handleBeforeSlide">
      <slide v-for="article in articles" :key="article.id" class="min-w-full outline-none">
        <button class="block text-left pr-20" @click="redirectArticle(article.path)">
          <h3 class="text-base leading-tight">
            <span class="text-base sm:text-md block mb-1 text-overflow text-red">{{ article.kicker }}</span>
            <span class="slide-heading text-md sm:text-lg font-bold text-grey-700 overflow-hidden">{{ article.title }}</span>
          </h3>
        </button>
      </slide>
    </hooper>
    <button class="fill-current absolute left-0 top-0 h-full translate-y ml-2 sm:ml-3" :class="slideArrrowClass" @click="slidePrev">
      <svg slot="hooper-prev" width="32" height="32" class="sm:w-10 sm:h-10">
        <use xlink:href="#chevron_left" />
      </svg>
    </button>
    <button class="fill-current absolute right-0 top-0 h-full translate-y mr-2 sm:mr-3" :class="slideArrrowClass" @click="slideNext">
      <svg slot="hooper-next" width="32" height="32" class="sm:w-10 sm:h-10">
        <use xlink:href="#chevron_right" />
      </svg>
    </button>
  </div>
</template>

<script lang="ts">
import { defineComponent } from '@vue/composition-api';
import { Hooper, Slide } from 'hooper';
import teaserTracking from '../../utils/tracking/teaserTracking.utils';

export default defineComponent({
  name: 'topbar-modal',
  components: {
    Hooper,
    Slide,
  },
  props: {
    articles: {
      type: Array,
      required: true,
    },
    activeItem: {
      type: Number,
    },
  },
  data() {
    return {
      slideArrrowClass: 'text-red',
      bubbleClick: false,
      hooperSettings: {
        itemsToShow: 1,
        infiniteScroll: true,
        wheelControl: false,
        initialSlide: 0,
      },
    };
  },
  watch: {
    activeItem: {
      handler(newActiveItem) {
        this.bubbleClick = true;
        (this.$refs.topbarCarousel as any)?.slideTo(newActiveItem);
      },
    },
  },
  methods: {
    handleSlide(slide: any) {
      let currentSlide = slide;

      // infinite scroll needs set border indexes
      if (currentSlide === -1) {
        currentSlide = this.articles.length - 1;
      } else if (currentSlide === this.articles.length) {
        currentSlide = 0;
      }

      if (this.articles[currentSlide]) {
        this.slideArrrowClass = 'text-red';
      }
      this.$emit('slideChange', currentSlide);
    },
    handleActionSlide(event: any) {
      if (event.currentSlide !== 0 || event.slideFrom !== null) this.handleSlide(event.currentSlide);
    },
    handleBeforeSlide(event: any) {
      if (event.currentSlide === 0) this.handleSlide(event.currentSlide);
    },
    slidePrev() {
      teaserTracking.manualTeaserTracking('topbar', 'slide_change_prev');
      (this.$refs.topbarCarousel as any)?.slidePrev();
    },
    slideNext() {
      teaserTracking.manualTeaserTracking('topbar', 'slide_change_next');
      (this.$refs.topbarCarousel as any)?.slideNext();
    },

    redirectArticle(url: any) {
      teaserTracking.manualTeaserTracking('topbar-click-article', url);
      window.location.href = `${url}?type=hybridapp&app=true`;
    },
  },
});
</script>
<style scoped>
@import 'hooper/dist/hooper.css';

.topbar-slider {
  max-width: calc(100vw - 2rem);
}
.hooper {
  height: auto;
}

.slide-heading {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
}
</style>
