<template>
  <div class="border-grey-100 border p-4 md:p-8 md:w-2/3">
    <h2 class="font-bold text-lg md:text-3xl">Empfohlener redaktioneller Inhalt</h2>
    <hr class="border-grey-300 my-4 md:my-6" />
    <div class="leading-normal mb-4 md:text-lg font-serif">
      An dieser Stelle finden Sie einen externen Inhalt von <b>{{ name }}</b
      >. Aufgrund ihrer Tracking-Einstellung ist die technische Darstellung nicht möglich. Mit dem Klick auf „Einstellungen öffnen“ können
      Sie die Inhalte akzeptieren.
    </div>
    <button class="btn btn-primary mb-4" @click="openSettings">Einstellungen öffnen</button>
    <section class="text-sm font-serif">
      Informationen finden Sie in unserer
      <a class="text-red underline" href="https://www.bunte.de/informationen-zum-datenschutz.html">Datenschutzerklärung</a>. Über die
      <button class="text-red underline" @click="openSettings">Datenschutzeinstellungen</button> können Sie die aktivierte Funktionen wieder
      deaktivieren.
    </section>
  </div>
</template>

<script lang="ts">
import { defineComponent } from '@vue/composition-api';
import { showManager } from '@/utils/cmp';

export default defineComponent({
  name: 'consent-dialog',
  props: {
    name: {
      type: String,
    },
  },
  methods: {
    openSettings() {
      showManager();
    },
  },
});
</script>

<style scoped>
.separator {
  border-bottom: 1px solid #dddddd;
}
</style>
