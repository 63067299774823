<template>
  <div class="mt-4">
    <span id="bio" class="overflow-hidden text-md leading-tight mb-2 block" :class="extendedBio ? 'h-auto mb-4' : 'h-15 fade'">
      {{ producerBio }}
    </span>
    <div class="flex justify-center h-5" :class="{ hidden: !bioOverflow }">
      <div :class="extendedBio ? 'transform rotate-180' : ''">
        <button @click="toggleBio()">
          <svg class="h-6 w-6">
            <use xlink:href="#chevrons-down" />
          </svg>
        </button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'moments-producer-extendable-bio',
  components: {},
  props: {
    producerBio: {
      type: String,
      required: false,
      default: '',
    },
  },
  data() {
    return {
      extendedBio: false,
      bioOverflow: false,
    };
  },

  computed: {},
  watch: {},
  mounted() {
    const bio = document.getElementById('bio');
    if (bio && bio?.scrollHeight > bio?.offsetHeight) {
      this.bioOverflow = true;
    } else {
      this.bioOverflow = false;
    }
  },
  methods: {
    toggleBio() {
      this.extendedBio = !this.extendedBio;
    },
  },
});
</script>

<style>
.fade {
  mask-image: -webkit-linear-gradient();
  -webkit-mask-image: linear-gradient(180deg, #000 60%, transparent);
}
</style>
