<template>
  <div id="newsletter-subscribe-teaser" class="my-6">
    <form
      class="newsletter shadow-xl p-6 md:px-10 md:py-10 bg-no-repeat lazyload"
      :class="[defaultLayout ? 'newsletter-default' : 'bg-center bg-cover']"
      :style="cssVars"
      novalidate
      @submit.prevent>
      <div v-if="!success" class="md:w-1/2">
        <h3 class="mb-3 xs:mb-4 text-2xl xs:text-3xl font-bold leading-tight w-5/6 xs:w-full">
          {{ title }}
        </h3>
        <div class="xs:flex xs:flex-wrap mb-6 xs:mb-4">
          <input
            v-model="user.email"
            type="email"
            placeholder="Deine E-Mail-Adresse"
            class="form-input mb-2 xs:mb-0 xs:mr-2 flex-1"
            :class="[error ? 'border-red' : '']"
            @keyup.enter="submit" />
          <alert v-if="error" alert-type="error" class="xs:order-last xs:w-full xs:mt-2">
            {{ error }}
          </alert>
          <button type="button" class="btn btn-primary w-full xs:w-32" @click="submit">Anmelden</button>
        </div>
        <label class="flex items-start">
          <span class="ml-2 text-xxs" v-html="legalHtml" />
        </label>
      </div>
      <div v-if="success" class="md:w-1/2">
        <h3 class="mb-3 xs:mb-4 text-2xl xs:text-3xl font-bold leading-tight w-5/6 xs:w-full">Willkommen bei BUNTE.de!</h3>
        <p class="pb-6 sm:text-md">
          Wir senden dir in Kürze eine Bestätigungs-E-Mail. Um die Anmeldung abzuschließen, klicke bitte auf den darin enthaltenen
          Aktivierungslink.
        </p>
        <p class="sm:text-md">Keine E-Mail von uns bekommen? Bitte schau auch in deinem SPAM Ordner nach.</p>
      </div>
    </form>
  </div>
</template>
<script lang="ts">
import { defineComponent } from '@vue/composition-api';
import errorMappings from '@/libs/errorMappings';
import commonUtils from '@/utils/common.utils';
import TrackingUtils from '@/utils/tracking/tracking.utils';
import * as bfNewsletterApi from '@/api/bfNewsletter.api';
import * as bfSSOApi from '@/api/bfSSO.api';
import alert from '@/components/alert.component.vue';
import { mapGroupNameToElaineGroupId } from './newsletter/elaine.utils';

export default defineComponent({
  name: 'newsletter-teaser-component',
  components: {
    alert,
  },
  props: {
    group: String,
    title: String,
    bgImageUrl: String,
    bgImageTabletUrl: String,
    bgImageDesktopUrl: String,
    defaultLayout: Boolean,
    colorText: {
      default: null,
      type: String,
    },
  },
  data() {
    return {
      user: {
        email: '',
      },
      success: false as any,
      error: false as any,
      legalVersion: null as String | null,
      legalHtml: null as String | null,
    };
  },
  computed: {
    cssVars(): any {
      return {
        '--bg-img': this.bgImageUrl,
        '--bg-img-tablet': this.bgImageTabletUrl,
        '--bg-img-desktop': this.bgImageDesktopUrl,
        '--color-text': this.colorText,
      };
    },
  },
  watch: {
    // eslint-disable-next-line func-names
    'user.email': function () {
      this.error = '';
      this.success = '';
    },
  },
  async mounted() {
    await this.getLegalData();
    TrackingUtils.trackEvent({
      event: 'register',
      eventCategory: 'register',
      eventAction: 'impression',
      eventLabel: 'newsletter-block',
      eventValue: undefined,
      registerType: this.group,
    });
  },
  methods: {
    async getLegalData() {
      const legalData = await bfSSOApi.getNewsletterLegalTextPublic();
      this.legalVersion = legalData.version;
      this.legalHtml = legalData.checkboxtext_html;
    },
    async submit() {
      this.error = commonUtils.verifyEmail(this.user.email);
      if (this.error !== '' || this.legalVersion === null) {
        this.status = '';
      } else {
        const data = {
          groupId: mapGroupNameToElaineGroupId(this.group),
          email: this.user.email,
          subscriptionLds: `nl_p1_bf_nleinwilligung-${this.legalVersion}`,
          referrer: `newsletter-block-${this.group || 'default'}`,
        };

        TrackingUtils.trackEvent({
          event: 'register',
          eventCategory: 'register',
          eventAction: 'success',
          eventLabel: 'newsletter-block',
          eventValue: undefined,
          registerType: this.group,
        });
        try {
          await bfNewsletterApi.subscribeBfNewsletterPublicByGroupId(data);
          this.success = 'Anmeldung erfolgreich.';
        } catch (e) {
          console.error(e);
          this.error = errorMappings.defaultServerError;
        }
      }
    },
  },
});
</script>

<style lang="postcss" scoped>
.newsletter.lazyloaded {
  background-image: var(--bg-img);
  color: var(--color-text);
}

@screen xs {
  .newsletter.lazyloaded {
    background-image: var(--bg-img-tablet);
  }
}

@screen md {
  .newsletter.lazyloaded {
    background-image: var(--bg-img-desktop);
  }
}

/* Styling for default image - different spacing */
.newsletter-default {
  @apply bg-contain bg-right-top;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.3);
  padding-top: 10.75rem;
}

@media screen and (min-width: 375px) {
  .newsletter-default {
    background-size: 345px auto;
  }
}

@screen xs {
  .newsletter-default {
    @apply pt-6 bg-contain bg-right-bottom;
    padding-bottom: 7.75rem;
  }
}

@screen sm {
  .newsletter-default {
    background-size: 60% auto;
  }
}

@media screen and (min-width: 890px) {
  .newsletter-default {
    background-size: 50% auto;
  }
}

@screen md {
  .newsletter-default {
    @apply pt-10 pb-12 bg-right-top;
    background-size: 456px auto;
  }
}
</style>
<style lang="postcss">
@screen md {
  .article-container .newsletter-default {
    background-size: 50% auto;
  }
}
</style>
