<template>
  <div class="">
    <svg class="h-6 w-6 xs:h-7 xs:w-7 toolbarIcon" @click="onReportClick">
      <use xlink:href="#moments-report-icon" />
    </svg>
  </div>
</template>

<script lang="ts">
import { defineComponent } from '@vue/composition-api';
import TrackingUtils from '../../utils/tracking/tracking.utils';
import { globalModalStore } from '@/store/globalModal.store';

export default defineComponent({
  name: 'moments-report-icon',
  components: {},
  props: {
    galleryId: {
      type: String,
      required: true,
    },
    galleryTitle: {
      type: String,
      required: true,
    },
    producerDisplayName: {
      type: String,
      required: true,
    },
    producerId: {
      type: String,
      required: true,
    },
    feedType: {
      type: String,
      required: true,
    },
    isVideoView: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      title: 'Warum möchtest du diese Galerie melden?',
      value: null as String | null,
      hasOptionalDescription: false,
      list: [
        {
          id: 1,
          hasOptionalDescription: true,
          value: 'Verstoß gegen meine Eigentumsrechte/Ich bin der Urheber',
        },
        {
          id: 2,
          hasOptionalDescription: true,
          value: 'Ich bin auf der Galerie abgebildet und habe nicht zugestimmt',
        },
        {
          id: 3,
          hasOptionalDescription: true,
          value: 'Die Galerie enthält unangemessene Inhalte',
        },
        {
          id: 4,
          hasOptionalDescription: false,
          value: 'Sonstiges',
        },
      ],
      showReportDialog: false,
      showConfirmationDialog: false,
      inputDescription: '',
      inputEmail: '',
      error: '',
      globalModalStore,
    };
  },
  computed: {},
  watch: {
    showReportDialog: {
      immediate: true,
      handler() {},
    },
  },
  methods: {
    setGlobalModalState() {
      this.globalModalStore.setReportDetails({
        galleryId: this.galleryId,
        galleryTitle: this.galleryTitle,
        producerDisplayName: this.producerDisplayName,
        producerId: this.producerId,
        feedType: this.feedType,
      });
      this.globalModalStore.setModalClosable();
      this.globalModalStore.setGlobalActiveModalContent('ReportForm');
      this.globalModalStore.setGlobalModalVisible();
    },
    onReportClick() {
      TrackingUtils.trackSnowplowEvent({
        eventName: 'pageElement.click',
        eventCategory: 'pageElement',
        eventAction: 'pageElement.clickInternal',
        eventLabel: this.galleryTitle,
        pageElementId: this.galleryId,
        pageElementName: 'report_gallery_click',
        pageElementType: this.feedType,
        pageElementPosition: `${this.producerid} | ${this.producerdisplayname}`,
        pageElementCreative: 'flag',
      });
      this.setGlobalModalState();
    },
  },
});
</script>

<style scoped>
svg {
  cursor: pointer;
}
/*TODO: write all the css in mobile first in order to avoid the crashing of the breakpoint at 520px or leave it at 519px*/
@media screen and (max-width: 519px) {
  svg {
    pointer-events: bounding-box;
    transform: translateZ();
    filter: drop-shadow(0px 0px 7px rgba(0, 0, 0, 1));
    -webkit-transform: translateZ(0);
    -webkit-filter: drop-shadow(0px 0px 7px rgba(0, 0, 0, 1));
  }
}
</style>
