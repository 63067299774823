<template>
  <div class="z-1 toolbarIcon">
    <svg class="h-6 w-6 xs:h-7 xs:w-7 text-red xs:text-red z-1" :class="{ hidden: !isLiked }" @click="onHeartClick">
      <use xlink:href="#moments-heart-icon-active" />
    </svg>
    <svg class="h-6 w-6 xs:h-7 xs:w-7 z-1" :class="{ hidden: isLiked }" @click="onHeartClick">
      <use xlink:href="#moments-heart-icon-inactive" />
    </svg>
  </div>
</template>

<script lang="ts">
import { defineComponent } from '@vue/composition-api';
import TrackingUtils from '../../utils/tracking/tracking.utils';
import { momentsStore } from '../../store/moments.store';

export default defineComponent({
  name: 'moments-heart-icon',
  components: {},
  props: {
    galleryid: {
      type: String,
      required: true,
    },
    gallerytitle: {
      type: String,
      required: true,
    },
    producerdisplayname: {
      type: String,
      required: true,
    },
    producerid: {
      type: String,
      required: true,
    },
    feedtype: {
      type: String,
      required: true,
    },
  },
  data() {
    return { isLiked: false };
  },
  async mounted() {
    this.isLiked = await momentsStore.likes(this.galleryid);
  },
  methods: {
    async onHeartClick() {
      this.isLiked = !this.isLiked;
      if (this.isLiked) {
        await momentsStore.likeGallery(this.galleryid);
        TrackingUtils.trackSnowplowEvent({
          eventName: 'pageElement.click',
          eventCategory: 'pageElement',
          eventAction: 'pageElement.clickInternal',
          eventLabel: this.gallerytitle,
          pageElementId: this.galleryid,
          pageElementName: 'like_gallery_click',
          pageElementType: this.feedtype,
          pageElementPosition: `${this.producerid} | ${this.producerdisplayname}`,
          pageElementCreative: 'heart',
        });
      } else {
        await momentsStore.unLikeGallery(this.galleryid);
        TrackingUtils.trackSnowplowEvent({
          eventName: 'pageElement.click',
          eventCategory: 'pageElement',
          eventAction: 'pageElement.clickInternal',
          eventLabel: this.gallerytitle,
          pageElementId: this.galleryid,
          pageElementName: 'unlike_gallery_click',
          pageElementType: this.feedtype,
          pageElementPosition: `${this.producerid} | ${this.producerdisplayname}`,
          pageElementCreative: 'heart',
        });
      }
    },
  },
});
</script>

<style scoped>
svg {
  cursor: pointer;
}

/*TODO: write all the css in mobile first in order to avoid the crashing of the breakpoint at 520px or leave it at 519px*/
@media screen and (max-width: 519px) {
  svg {
    pointer-events: bounding-box;
    transform: translateZ(0);
    filter: drop-shadow(0px 0px 7px rgba(0, 0, 0, 1));
    -webkit-transform: translateZ(0);
    -webkit-filter: drop-shadow(0px 0px 7px rgba(0, 0, 0, 1));
  }

  .icon-hide {
    opacity: 0;
  }
}
</style>
