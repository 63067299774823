<template>
  <form v-if="!showSuccessMessage" class="shadow-registration container-registration tricolorBadge relative" @submit.prevent="submitForm">
    <h2 class="font-size-title margin-fix pt-7 sm:pt-6 leading-loose text-3xl text-center font-bold text-black">
      Jetzt als BUNTE Moments Creator bewerben!
    </h2>
    <input class="py-4 w-full border border-solid border-grey-500 pl-3 text-base mb-4" type="text" readonly :placeholder="userEmail" />

    <moments-social-media-input-field v-model="instagramUsername"></moments-social-media-input-field>

    <p class="margin-fix text-sm pt-1">Die Verifizierung erfolgt über dieses Profil.</p>
    <button v-if="!apiCallInProgress" type="submit" class="btn w-full bg-momentsOrange text-white uppercase m-0" :disabled="!isValidForm">
      Jetzt bewerben
    </button>
    <button v-else class="btn w-full bg-momentsOrange text-white uppercase m-0 text-sm tracking-normal flex items-center justify-center">
      <div class="spinner mr-2"></div>
      Lädt
    </button>
    <p class="margin-info-text mt-2 sm:mt-0 sm:pt-2 text-xs leading-normal">
      Wir nutzen deine E-Mail-Adresse, neben der Durchführung deiner Bewerbung, um dich über Neuigkeiten von BUNTE Moments sowie zu
      interessanten Angeboten per E-Mail zu informieren. Du kannst jederzeit unter
      <a class="links underline" href="mailto:bunte@datenschutzanfrage.de">bunte@datenschutzanfrage.de</a> widersprechen. Es gelten die
      <a class="links underline" target="_blank" rel="noopener" href="https://www.bunte.de/allgemeine-nutzungsbedingungen-buntede.html">
        Nutzungsbedingungen
      </a>
      von BUNTE.de. Die Verarbeitung deiner Daten erfolgt entsprechend der
      <a class="links underline" target="_blank" rel="noopener" href="https://www.bunte.de/informationen-zum-datenschutz.html">
        Datenschutzerklärung
      </a>
      .<br /><br />
    </p>
    <div class="pt-4 pb-6 sm:pb-8 md:pt-6 sm:px-4 sm:px-0 mx-auto text-md text-black text-center border-t border-grey-500">
      <span class="tracking-normal text-base leading-none mr-1 md:text-lg">Du hast bereits ein Konto?</span>
      <button class="tracking-normal text-base text-momentsOrange underline md:text-lg" @click="openLoginModal()">Hier Anmelden</button>
    </div>
  </form>
  <moments-registration-success-modal v-else></moments-registration-success-modal>
</template>

<script lang="ts">
import { defineComponent } from '@vue/composition-api';
import { userStore } from '@/store/user.store';

import sessionStorageUtils from '@/utils/sessionStorage.utils';
import * as momentsApi from '@/api/moments.api';

import * as bfNewsletterApi from '@/api/bfNewsletter.api';
import * as bfSSOApi from '@/api/bfSSO.api';
import errorMappings from '@/libs/errorMappings';
import commonUtils from '@/utils/common.utils';

import TrackingUtils from '@/utils/tracking/tracking.utils';

export default defineComponent({
  name: 'moments-registration-form-after-login',
  components: {
    momentsSocialMediaInputField: () => import('./momentsSocialMediaInputField.component.vue'),
    momentsRegistrationSuccessModal: () => import('../../components/moments/momentsRegistrationSuccessModal.component.vue'),
  },
  props: {},
  data() {
    return {
      user: {} as any,
      email: null,
      userStore,
      apiCallInProgress: false,
      instagramUsername: '',
      emailUser: userStore.user!.email,
      showSuccessMessage: false,
      legalVersion: null as String | null,
      legalHtml: null as String | null,
    };
  },
  computed: {
    userEmail() {
      return userStore.user?.email;
    },
    isValidForm(): boolean {
      const userEmail = userStore.user?.email;
      return userEmail !== undefined && this.isValidInstagramUsername();
    },
  },
  async mounted() {
    this.getLegalData();
    const isRegistrationUserExistsFlow = sessionStorageUtils.getAndRemove('user:moments:registration-user-exists-flow');

    if (isRegistrationUserExistsFlow) {
      this.showSuccessMessage = true;
    }
  },
  methods: {
    async getLegalData() {
      const legalData = await bfSSOApi.getNewsletterLegalTextPublic();
      this.legalVersion = legalData.version;
      this.legalHtml = legalData.checkboxtext_html;
    },
    isValidInstagramUsername(): boolean {
      return this.instagramUsername.trim() !== '';
    },
    async submitForm() {
      await this.createMomentsCreatorNewsletterUser();
      await this.onSubmitForm();
    },
    async createMomentsCreatorNewsletterUser() {
      this.error = commonUtils.verifyEmail(this.emailUser);
      if (this.error !== '' || this.legalVersion === null) {
        this.status = '';
      } else {
        const data = {
          groupId: '1001361', // Moments creator newsletter
          email: this.emailUser,
          subscriptionLds: `nl_p1_bf_nleinwilligung-${this.legalVersion}`,
          referrer: 'registration-moments-creator',
        };
        TrackingUtils.trackEvent({
          event: 'register',
          eventCategory: 'register',
          eventAction: 'success',
          eventLabel: 'registration',
          eventValue: undefined,
          registerType: 'moments-creator',
        });
        try {
          const result = await bfNewsletterApi.subscribeBfNewsletterPublicByGroupId(data);
          this.success = 'Anmeldung erfolgreich.';
        } catch (e) {
          console.error(e);
          this.error = errorMappings.defaultServerError;
        }
      }
    },
    async onSubmitForm() {
      if (!this.isValidForm || this.apiCallInProgress) {
        return; // Prevent form submission if form is invalid or an API call is in progress
      }
      this.apiCallInProgress = true;
      try {
        const result = await momentsApi.createMomentsUserExistingCustomer(this.emailUser, this.instagramUsername, 'elaine');
        this.showSuccessMessage = true;
      } catch (e) {
        //console.log('WE GOT AN ERROR', e);
      } finally {
        this.apiCallInProgress = false;
      }
    },

    openLoginModal() {
      const { email } = this.user;
      if (email) {
        this.userStore.loginEmail = email;
      }
      this.userStore.loginVisible = true;
    },
  },
});
</script>

<style scoped>
.container-registration {
  width: 100%;
  padding: 0px 16px;
}
@media screen and (min-width: 520px) {
  .container-registration {
    max-width: 604px;
    padding: 0px 63px;
  }
}
@media screen and (min-width: 768px) {
  .container-registration {
    max-width: 800px;
    padding: 0px 130px;
    margin: auto;
  }
}

.shadow-registration {
  box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.3);
}

.links {
  color: #f39200;
}

.error-message-container {
  width: 100%;
  padding: 12px 17px;
  background-color: rgba(255, 0, 0, 0.08);
  border: 1px solid rgba(255, 0, 0);
  margin-bottom: 20px;
}
.error-message {
  color: rgba(255, 0, 0);
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: 0.64px;
}
.input-container {
  position: relative;
}
.input-field {
  padding-left: 183px; /* Adjust the value based on the length of the prefix */
}
.margin-fix {
  margin-bottom: 16px;
}
.margin-divider {
  margin-top: 22px;
}
.margin-info-text {
  margin-bottom: 24px;
}
.font-size-title {
  font-size: 26px !important; /* I have to force the size to avoid the global font-size for all the h2*/
}
.spinner,
.spinner:after {
  border-radius: 50%;
  width: 10em;
  height: 10em;
}
.spinner {
  font-size: 2px;
  border-top: 2em solid white;
  border-right: 2em solid white;
  border-bottom: 2em solid #f39200;
  border-left: 2em solid white;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: spinner 1.1s infinite linear;
  animation: spinner 1.1s infinite linear;
}

@-webkit-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
</style>
