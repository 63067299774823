<template>
  <form v-if="!showSuccessMessage" class="shadow-registration container-registration tricolorBadge relative" @submit.prevent="submitForm">
    <h2 class="font-size-title margin-fix pt-7 sm:pt-6 leading-loose text-3xl text-center font-bold text-black">
      Jetzt als BUNTE Moments Creator bewerben!
    </h2>
    <input
      v-model="emailUser"
      class="py-4 w-full border border-solid border-grey-500 pl-3 text-base mb-4"
      type="text"
      placeholder="Deine E-Mail Adresse*" />

    <input
      v-model="password"
      type="password"
      class="py-4 w-full border border-solid border-grey-500 pl-3 text-base mb-4"
      placeholder="Passwort*"
      @blur="checkIsValidPassword(password)" />

    <moments-social-media-input-field v-model="instagramUsername"></moments-social-media-input-field>

    <p class="margin-fix text-sm pt-1">Die Verifizierung erfolgt über dieses Profil.</p>
    <div v-if="errorMessageEmail.length > 0 || errorMessagePass.length > 0" class="error-message-container">
      <span class="error-message" style="white-space: pre-line"> {{ errorMessageEmail }} {{ errorMessagePass }}</span>
    </div>
    <button
      v-if="!apiCallInProgress"
      type="submit"
      class="btn w-full bg-momentsOrange text-white uppercase m-0"
      :disabled="!isValidForm"
      @click="submitForm">
      Jetzt bewerben
    </button>
    <button v-else class="btn w-full bg-momentsOrange text-white uppercase m-0 text-sm tracking-normal flex items-center justify-center">
      <div class="spinner mr-2"></div>
      Lädt
    </button>
    <p class="margin-info-text mt-2 sm:mt-0 sm:pt-2 text-xs leading-normal">
      Wir nutzen deine E-Mail-Adresse, neben der Durchführung deiner Bewerbung, um dich über Neuigkeiten von BUNTE Moments sowie zu
      interessanten Angeboten per E-Mail zu informieren. Du kannst jederzeit unter
      <a class="links underline" href="mailto:bunte@datenschutzanfrage.de">bunte@datenschutzanfrage.de</a> widersprechen. Es gelten die
      <a class="links underline" target="_blank" rel="noopener" href="https://www.bunte.de/allgemeine-nutzungsbedingungen-buntede.html">
        Nutzungsbedingungen
      </a>
      von BUNTE.de. Die Verarbeitung deiner Daten erfolgt entsprechend der
      <a class="links underline" target="_blank" rel="noopener" href="https://www.bunte.de/informationen-zum-datenschutz.html">
        Datenschutzerklärung
      </a>
      .<br /><br />
    </p>

    <div class="pt-4 pb-6 sm:pb-8 md:pt-6 sm:px-4 sm:px-0 mx-auto text-md text-black text-center border-t border-grey-500">
      <span class="tracking-normal text-base leading-none mr-1 md:text-lg">Du hast bereits ein Konto?</span>
      <button class="tracking-normal text-base text-momentsOrange underline md:text-lg" @click="openLoginModal()">Hier Anmelden</button>
    </div>
  </form>
  <moments-registration-success-modal v-else></moments-registration-success-modal>
</template>

<script lang="ts">
import { defineComponent } from '@vue/composition-api';
import { userStore } from '@/store/user.store';
import sessionStorageUtils from '@/utils/sessionStorage.utils';
import * as momentsApi from '@/api/moments.api';
import * as bfSSOApi from '@/api/bfSSO.api';

import TrackingUtils from '@/utils/tracking/tracking.utils';
import { mapGroupNameToElaineNewsletterId } from '../newsletter/elaine.utils';
import { getUserExistsWithEmail } from '@/api/moments.api';

export default defineComponent({
  name: 'moments-registration-form',
  components: {
    momentsSocialMediaInputField: () => import('../../components/moments/momentsSocialMediaInputField.component.vue'),
    momentsRegistrationSuccessModal: () => import('../../components/moments/momentsRegistrationSuccessModal.component.vue'),
  },
  props: {},
  data() {
    return {
      emailUser: '',
      password: '',
      instagramUsername: '',
      user: {} as any,
      email: null,
      userStore,
      errorMessageEmail: '',
      errorMessagePass: '',
      showSuccessMessage: false,
      apiCallInProgress: false,
      legalVersion: null as String | null,
      legalHtml: null as String | null,
    };
  },
  computed: {
    isValidInstagramUsername(): boolean {
      return this.instagramUsername.trim() !== '';
    },
    isValidForm(): boolean {
      const validEmail = this.checkIsValidUsername(this.emailUser);
      const validPass = this.checkIsValidPassword(this.password);
      return validEmail && validPass && this.isValidInstagramUsername;
    },
  },
  async mounted() {
    this.getLegalData();
  },
  methods: {
    async getLegalData() {
      const legalData = await bfSSOApi.getNewsletterLegalTextPublic();
      this.legalVersion = legalData.version;
      this.legalHtml = legalData.checkboxtext_html;
    },
    async submitForm() {
      await this.onSubmitForm();
    },

    async trackRegistration() {
      TrackingUtils.trackEvent({
        event: 'register',
        eventCategory: 'register',
        eventAction: 'success',
        eventLabel: 'registration',
        eventValue: undefined,
        registerType: 'moments-creator',
      });
      this.success = 'Anmeldung erfolgreich.';
    },
    getSubscribeData(groupNames: string[]) {
      const newslettersIds: (string | undefined)[] = groupNames.map((groupName) => {
        return mapGroupNameToElaineNewsletterId(groupName);
      });
      return {
        newsletters: newslettersIds,
        email: this.emailUser,
        subscriptionLds: `nl_p1_bf_nleinwilligung-${this.legalVersion}`,
        referrer: 'registration-moments-creator',
        silentSubscribe: true,
      };
    },
    async onSubmitForm() {
      if (!this.isValidForm || this.apiCallInProgress) {
        return; // Prevent form submission if form is invalid or an API call is in progress
      }
      this.apiCallInProgress = true;
      try {
        await getUserExistsWithEmail(this.emailUser);

        // user exists, show login modal
        this.userStore.loginResultCallback = () => {
          sessionStorageUtils.set('user:moments:registration-user-exists-flow', '1');
        };
        this.openLoginModal('Du hast schon einen Account bei BUNTE.de. Wenn du dein Passwort vergessen hast, kannst du es zurücksetzen.');
      } catch (e) {
        //user does not exist, create new user
        const createUserResult = await momentsApi.createMomentsUser(
          this.emailUser,
          this.password,
          this.instagramUsername,
          this.getSubscribeData(['moments'])
        );
        //@ts-ignore
        if (!createUserResult.customerCreated) {
          throw Error('Cannot Create new User');
        } else {
          await this.trackRegistration();
          if (window.flutter_inappwebview) {
            window.flutter_inappwebview.callHandler('webLoginHandler', this.emailUser, this.password);
          }
          this.showSuccessMessage = true;
        }
      } finally {
        this.apiCallInProgress = false;
      }
    },
    openLoginModal(initialError?: string) {
      const { email } = this.user;
      if (email) {
        this.userStore.loginEmail = email;
      }
      if (initialError) {
        this.userStore.loginInitialError = initialError;
      }
      this.userStore.loginVisible = true;
    },

    checkIsValidUsername(username: string): boolean {
      let errors = '';
      let isValid = true;

      if (username === null || username === '') {
        isValid = false;
        this.errorMessageEmail = '';
      } else if (!/^[^\s@]+@[^\s@]+$/.test(username)) {
        isValid = false;
        errors += 'Die E-Mail-Adresse ist ungültig.\n';
      }

      this.errorMessageEmail = errors;
      return isValid;
    },

    checkIsValidPassword(password: string): boolean {
      let errors = '';
      let isValid = true;

      if (password === null || password.trim() === '') {
        this.errorMessagePass = '';
        isValid = false;
      } else {
        // Password validation checks
        if (!/\d/.test(password)) {
          errors += 'Das Passwort muss Zahlen enthalten.\n';
          isValid = false;
        }
        if (password.length < 8) {
          errors += 'Das Passwort muss mindestens 8 Zeichen lang sein.\n';
          isValid = false;
        }
        if (!/[A-Z]/.test(password)) {
          errors += 'Das Passwort muss Großbuchstaben enthalten.\n';
          isValid = false;
        }
        if (!/[a-z]/.test(password)) {
          errors += 'Das Passwort muss Kleinbuchstaben enthalten.\n';
          isValid = false;
        }
      }

      if (password === this.password || password.trim() === '') {
        this.errorMessagePass = errors;
      }
      return isValid;
    },
  },
});
</script>

<style scoped>
.container-registration {
  width: 100%;
  padding: 0px 16px;
}
@media screen and (min-width: 520px) {
  .container-registration {
    max-width: 604px;
    padding: 0px 63px;
  }
}
@media screen and (min-width: 768px) {
  .container-registration {
    max-width: 800px;
    padding: 0px 130px;
    margin: auto;
  }
}

.shadow-registration {
  box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.3);
}

.links {
  color: #f39200;
}

.error-message-container {
  width: 100%;
  padding: 12px 17px;
  background-color: rgba(255, 0, 0, 0.08);
  border: 1px solid rgba(255, 0, 0);
  margin-bottom: 20px;
}
.error-message {
  color: rgba(255, 0, 0);
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: 0.64px;
}
.input-container {
  position: relative;
}
.input-field {
  padding-left: 183px; /* Adjust the value based on the length of the prefix */
}
.margin-fix {
  margin-bottom: 16px;
}
.margin-divider {
  margin-top: 22px;
}
.margin-info-text {
  margin-bottom: 24px;
}
.font-size-title {
  font-size: 26px !important; /* I have to force the size to avoid the global font-size for all the h2*/
}

.spinner,
.spinner:after {
  border-radius: 50%;
  width: 10em;
  height: 10em;
}
.spinner {
  font-size: 2px;
  position: relative;
  text-indent: -9999em;
  border-top: 2em solid white;
  border-right: 2em solid white;
  border-bottom: 2em solid #f39200;
  border-left: 2em solid white;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: spinner 1.1s infinite linear;
  animation: spinner 1.1s infinite linear;
}

@-webkit-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
</style>
